<template>
  <div class="home page-wrapper" v-if="!silent">
    <div class="page-body">
      <div class="container-xl">
        <div class="row row-cards">
          <div class="offset-4 col-md-4">
            <div class="card">
              <div v-if="loading" class="progress progress-sm card-progress">
                <div
                  class="progress-bar progress-bar-indeterminate"
                  role="progressbar"
                ></div>
              </div>
              <div class="card-status-top bg-success" v-else-if="success"></div>
              <div class="card-status-top bg-danger" v-else></div>
              <div class="card-body">
                <div class="d-flex align-items-center" v-if="loading">
                  <span class="bg-blue text-white avatar me-2">
                    <!-- Download SVG icon from http://tabler-icons.io/i/currency-dollar -->
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-lock"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <rect x="5" y="11" width="14" height="10" rx="2"></rect>
                      <circle cx="12" cy="16" r="1"></circle>
                      <path d="M8 11v-4a4 4 0 0 1 8 0v4"></path>
                    </svg>
                  </span>
                  <h3 class="mb-0">Logging in...</h3>
                </div>
                <div class="d-flex align-items-center" v-else-if="success">
                  <span class="bg-green text-white avatar me-2">
                    <!-- Download SVG icon from http://tabler-icons.io/i/currency-dollar -->
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-circle-check"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <circle cx="12" cy="12" r="9"></circle>
                      <path d="M9 12l2 2l4 -4"></path>
                    </svg>
                  </span>
                  <h3 class="mb-0">Login success.</h3>
                </div>
                <div class="d-flex align-items-center" v-else>
                  <span class="bg-red text-white avatar me-2">
                    <!-- Download SVG icon from http://tabler-icons.io/i/currency-dollar -->
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-circle-x"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <circle cx="12" cy="12" r="9"></circle>
                      <path d="M10 10l4 4m0 -4l-4 4"></path>
                    </svg>
                  </span>
                  <h3 class="mb-0">
                    Token invalid.
                    <router-link to="/login"> Sign in &raquo; </router-link>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TrackingService from "../services/TrackingService";

export default {
  data: () => {
    return {
      loading: true,
      success: false,
      silent: false,
    };
  },
  async created() {
    if (this.$route.query.silent) {
      this.silent = true;
    }
    var sudoId = this.$route.query.sudo;
    let companyHint = this.$route.query.company;
    try {
      let sudo = await this.$auth.sudo(sudoId);
      if (sudo) {
        this.success = true;
        this.loading = false;
        await this.$auth.identify();
        await this.$auth.clearCache();
        if (companyHint) {
          this.$auth.saveActiveCompany(companyHint);
        }

        TrackingService.track("logged in using login token");

        if (this.$route.query.next) {
          this.$router.replace(this.$route.query.next);
        } else {
          this.$router.replace("/");
        }
      }
    } catch (e) {
      this.loading = false;
      this.$router.replace("/login");
    }
  },
};
</script>
